import type { DialogueNodeData } from '@common/studio-types';
import {
  Field,
  ImageInput,
  RadioButtons,
  TextInput,
  UploadedImage,
} from '@maestro/components';
import { Textarea } from '@maestro/components/Textarea';
import { dimensions } from '@maestro/styles';
import styled from 'styled-components';
import { Hint } from '../../components/Hint';
import { Label } from '../../components/Label';
import { Warning } from '../../components/Warning';
import { StudioNodeFormComponent } from '../Node.types';

const maxCharacters = 255;

export const directions: {
  value: string;
  label: string;
}[] = [
  { value: 'left', label: 'Left' },
  { value: 'right', label: 'Right' },
];

export const DialogueForm: StudioNodeFormComponent<DialogueNodeData> = ({
  data,
  onChange,
}) => {
  const onUpload = (result?: UploadedImage) => {
    onChange({
      ...data,
      characterImage: result?.path ?? '',
    });
  };

  return (
    <Container>
      <Hint>
        The narrator response is the literary voice that conveys the story to
        your players
      </Hint>
      <ImageInput
        value={data.characterImage}
        uploadPath="episodes"
        onChange={onUpload}
      />
      <Field label="Character name">
        <TextInput
          placeholder="Character name"
          maxCharacters={50}
          autoFocus={!data.characterName}
          value={data.characterName ?? ''}
          onChange={(e) => onChange({ ...data, characterName: e.target.value })}
        />
      </Field>

      <Field label="Action">
        <TextInput
          placeholder="said"
          maxCharacters={16}
          autoFocus={!data.action}
          value={data.action ?? ''}
          onChange={(e) => onChange({ ...data, action: e.target.value })}
        />
      </Field>

      <Label>Dialogue message</Label>
      <Textarea
        placeholder="Write a dialogue message here"
        maxCharacters={maxCharacters}
        autoFocus={!data.message}
        value={data.message}
        onChange={(e) => onChange({ ...data, message: e.target.value })}
      />
      {data.message.length > maxCharacters && (
        <Warning>
          We recommend less than {maxCharacters} characters for each dialogue
          message. Try breaking it up by adding a player choice, image or adding
          another narrator response to add more characters.
        </Warning>
      )}

      <Field label="Direction">
        <RadioButtons
          options={directions}
          value={data.direction}
          onChange={(direction) => onChange({ ...data, direction })}
        />
      </Field>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  width: 100%;
`;
