import { DialogueNodeData, StudioNodeType } from '@common/studio-types';
import { toNodeId } from '../../toId';
import type { NodeConfig } from '../Node.types';
import { DialogueForm } from './DialogueForm';
import { DialogueNode } from './DialogueNode';

const name = 'Dialogue';

export const dialogueNode: NodeConfig<
  StudioNodeType.Dialogue,
  DialogueNodeData
> = {
  type: StudioNodeType.Dialogue,
  name,
  icon: 'narrator-text',
  node: DialogueNode,
  form: DialogueForm,
  cloneNodeData: ({
    message,
    characterImage,
    characterName,
    action,
    direction,
  }) => ({
    data: {
      id: toNodeId(),
      message,
      characterImage,
      characterName,
      action,
      direction,
    },
  }),
  getNodeData: (data) => ({ title: name, description: data.message }),
  createNodeData: () => ({
    id: toNodeId(),
    type: StudioNodeType.Dialogue,
    characterName: '',
    characterImage: '',
    message: '',
    action: 'said',
    direction: 'left',
  }),
};
