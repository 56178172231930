import type {
  CoinTossInteractionMessage,
  CoinTossResultMessage,
  EpisodeProgressMessage,
  ImageMessage,
  InventoryItemChangeMessage,
  Message,
  PlayerAttributeMessage,
  SelectedInteractionOptionMessage,
  SingleSelectInteractionMessage,
  TextMessage,
  DiceRollInteractionMessage,
  DiceRollResultMessage,
  StatBlockMessage,
  EndEpisodeMessage,
  PlayerInputMessage,
  PlayerInputResultMessage,
  DialogueMessage,
} from './messages.types';

export const isTextMessage = (message: Message): message is TextMessage => {
  return message.type === 'text';
};

export const isEndEpisodeMessage = (
  message: Message,
): message is EndEpisodeMessage => {
  return message.type === 'end-episode';
};

export const isEpisodeProgressMessage = (
  message: Message,
): message is EpisodeProgressMessage => {
  return message.type === 'episode-progress';
};

export const isImageMessage = (message: Message): message is ImageMessage => {
  return message.type === 'image';
};

export const isStatBlockMessage = (
  message: Message,
): message is StatBlockMessage => {
  return message.type === 'stat-block';
};

export const isPlayerAttributeMessage = (
  message: Message,
): message is PlayerAttributeMessage => {
  return message.type === 'player-attribute';
};

export const isSingleSelectInteractionMessage = (
  message: Message,
): message is SingleSelectInteractionMessage => {
  return message.type === 'single-select-interaction';
};

export const isSelectedInteractionOptionMessage = (
  message: Message,
): message is SelectedInteractionOptionMessage => {
  return message.type === 'selected-interaction-option';
};

export const isInventoryItemChangeMessage = (
  message: Message,
): message is InventoryItemChangeMessage => {
  return message.type === 'inventory-item-change';
};

export const isCoinTossInteractionMessage = (
  message: Message,
): message is CoinTossInteractionMessage => {
  return message.type === 'coin-toss-interaction';
};

export const isPlayerInputMessage = (
  message: Message,
): message is PlayerInputMessage => {
  return message.type === 'player-input';
};

export const isPlayerInputResultMessage = (
  message: Message,
): message is PlayerInputResultMessage => {
  return message.type === 'player-input-result';
};

export const isCoinTossResultMessage = (
  message: Message,
): message is CoinTossResultMessage => {
  return message.type === 'coin-toss-result';
};

export const isDiceRollInteractionMessage = (
  message: Message,
): message is DiceRollInteractionMessage => {
  return message.type === 'dice-roll-interaction';
};

export const isDiceRollResultMessage = (
  message: Message,
): message is DiceRollResultMessage => {
  return message.type === 'dice-roll-result';
};

export const isDialogueMessage = (
  message: Message,
): message is DialogueMessage => {
  return message.type === 'dialogue';
};
