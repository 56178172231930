import { dimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { InterpolatedText } from '../../components/value/InterpolatedText';
import { PfpLabel } from './PfpLabel';

type DialogueProps = {
  characterName: string;
  characterAvatarUrl?: string;
  text: string;
  direction: 'left' | 'right';
  action: string;
};

export const Dialogue: React.FC<DialogueProps> = (props) => {
  const { characterName, characterAvatarUrl, text, action } = props;

  return (
    <PfpLabel
      name={characterName}
      avatarUrl={characterAvatarUrl}
      action={action}
      direction={props.direction}
    >
      <Container $direction={props.direction}>
        <DialogueText>
          <InterpolatedText text={text} />
        </DialogueText>
      </Container>
    </PfpLabel>
  );
};

const Container = styled.div<{
  $direction: 'left' | 'right';
}>`
  background-color: ${({ theme }) => theme.colors.background.shade};
  border: ${dimensions.size1} solid
    ${({ theme }) => theme.colors.border.default[100]};
  border-radius: ${({ $direction }) =>
      $direction === 'left' ? dimensions.size4 : dimensions.size24}
    ${({ $direction }) =>
      $direction === 'left' ? dimensions.size24 : dimensions.size4}
    ${dimensions.size24} ${dimensions.size24};
  overflow: hidden;
`;

const DialogueText = styled.div`
  ${textStyles.body.b16m}
  color: ${({ theme }) => theme.colors.gameplay.activeDialogueText};
  padding: ${dimensions.size16};
`;
