import type { DialogueNodeData } from '@common/studio-types';
import React, { memo } from 'react';
import { NodeProps, Position } from 'reactflow';
import styled from 'styled-components';
import { BaseStudioNode } from '../BaseStudioNode';
import { Handle } from '../Handle';
import { Dialogue } from './Dialogue';

type Props = NodeProps<DialogueNodeData>;

const Node: React.FC<Props> = ({ id, data, selected }) => {
  return (
    <BaseStudioNode
      title="Dialogue"
      selected={selected}
      nodeId={id}
      node={data}
    >
      <Handle
        nodeId={id}
        type="target"
        position={Position.Top}
        id="target-top"
      />
      <Text>
        <Dialogue
          action={data.action}
          characterName={data.characterName}
          characterAvatarUrl={data.characterImage}
          direction={data.direction}
          text={data.message}
        />
      </Text>
      <Handle
        nodeId={id}
        type="source"
        position={Position.Bottom}
        id="source-bottom"
      />
    </BaseStudioNode>
  );
};

export const DialogueNode = memo(Node);

const Text = styled.div`
  white-space: pre-line;
`;
