import { dimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';

type Props = {
  name: string;
  avatarUrl?: string;
  action?: string;
  children: React.ReactNode;
  direction: 'left' | 'right';
};

export const PfpLabel: React.FC<Props> = ({
  name,
  action,
  avatarUrl,
  children,
  direction,
}) => {
  return (
    <Container $direction={direction}>
      <RightContent $direction={direction}>
        <NameContainer>
          <Name>{name}</Name>
          {action && <Action>{action}</Action>}
        </NameContainer>
        {children}
      </RightContent>
      {avatarUrl && <Image src={avatarUrl} alt={name} />}
    </Container>
  );
};

const Image = styled.img`
  width: ${dimensions.size48};
  height: ${dimensions.size48};
  border-radius: ${dimensions.size12};
  object-fit: cover;
`;

const Container = styled.div<{ $direction: 'left' | 'right' }>`
  display: flex;
  flex-direction: ${({ $direction }) =>
    $direction === 'left' ? 'row-reverse' : 'row'};
  gap: ${dimensions.size12};
`;

const RightContent = styled.div<{ $direction: 'left' | 'right' }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  align-items: flex-end;
  align-items: ${({ $direction }) =>
    $direction === 'left' ? 'flex-start' : 'flex-end'};
`;

const Name = styled.div`
  ${textStyles.body.b16m}
  color: ${({ theme }) => theme.colors.text.header};
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size4};
`;

const Action = styled.div`
  ${textStyles.body.b16m}
  color: ${({ theme }) => theme.colors.text.body};
`;
